<template>
  <div>
    <apexchart
      height="500"
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apexchart from 'vue-apexcharts'
export default {
    name: 'TemperatureGraph',
    components: {apexchart},
    data(){
        return {
            temperatures: [],
            poids: [],
            frs: [],
            fcs: [],
            dates: []
        }
    },
    watch: {
        animalConstantes:{
            handler(){
                this.temperatures = []
                this.poids = []
                this.frs = []
                this.fcs = []
                this.dates = []
                if(this.animalConstantes.length > 0){
                    this.animalConstantes.forEach(item => {
                        this.temperatures.push(item.temperature)
                        this.poids.push(item.poid)
                        this.frs.push(item.fr)
                        this.fcs.push(item.fc)
                        if(item.operationDate !== null) this.dates.push(item.operationDate)
                        else this.dates.push(item.createdAt)
                    })
                }else {
                    this.temperatures = []
                    this.poids = []
                    this.frs = []
                    this.fcs = []
                    this.dates = []
                }
            },
            deep: true
        }
    },
    computed:{
        ...mapGetters({
            animaux: 'identification/animaux',
            constantes: 'identification/constantes',
            selectedAnimal: 'identification/selectedAnimal'
        }),
        animalConstantes(){
            if(this.selectedAnimal !== null) return [...this.constantes].filter(item => item.animal === this.selectedAnimal)
            return []
        },
        animalIso(){
            if(this.selectedAnimal !== null){
                let a = this.animaux.find(item => item.uid === this.selectedAnimal)
                if(a) return a.codeIso
                return '-'
            }
            return '-'
        },
        chartOptions() {
            return {
                chart: {
                    id: 'temperature-poids-chart',
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#1C6100', '#BB1B1B'],
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Temperature et Poids de '+ this.animalIso,
                    align: 'left'
                },
                legend: {
                   position: 'bottom',
                   horizontalAlign: 'right'
                },
                yaxis: [{
                    title: {
                        text: 'Poids (Kg)',
                    },
                    max: 120
            
                    }, 
                    {
                        opposite: true,
                        title: {
                            text: 'Temperature (°C)'
                        },
                        max: 50
                    }
                ],
                xaxis: {
                    type: 'datetime',
                    categories: this.dates,
                },
            }
        },
        series(){
            return [
                {
                    name: "Poids (kg)",
                    data: this.poids
                },
                {
                    name: "Temperature (°C)",
                    data: this.temperatures
                }
               
            ]   
        }
        
    }
}
</script>

<style>

</style>