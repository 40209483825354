<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-12">
        <animal-detail :show-detail="true" />   
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <temperature-graph />
      </div>
      <div class="col-6">
        <other-graph />
      </div>
    </div>
  </div>
</template>

<script>
import navbar from '../../../components/navbar.vue';
import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
import TemperatureGraph from '../../../components/identification/constante/temperatureGraph.vue'
import OtherGraph from '../../../components/identification/constante/otherConstantGraph.vue'
export default {
    components: {navbar, AnimalDetail, TemperatureGraph, OtherGraph  },
    data(){
        return {
            navbarItems: [{
                libelle: 'Identification'
              },
              {
                libelle: 'Constantes',
                route: 'constant'
              },
              {
                libelle: 'Graphique'
              }],
              pageIcon: 'la-stethoscope',
              pageTitle: 'Graphique',
              pageDescription: 'Représentation graphique des constantes',
        }
    }
}
</script>

<style>

</style>