<template>
  <div>
    <apexchart
      height="500"
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex'
  import apexchart from 'vue-apexcharts'
  export default {
      name: 'TemperatureGraph',
      components: {apexchart},
      data(){
          return {
              temperatures: [],
              frs: [],
              fcs: []
          }
      },
      watch: {
          animalConstantes:{
              handler(){
                  this.frs = []
                  this.fcs = []
                  this.dates = []
                  if(this.animalConstantes.length > 0){
                      this.animalConstantes.forEach(item => {
                          this.frs.push(item.fr)
                          this.fcs.push(item.fc)
                          if(item.operationDate !== null) this.dates.push(item.operationDate)
                        else this.dates.push(item.createdAt)
                      })
                  }else {
                      this.frs = []
                      this.fcs = []
                      this.dates = []
                  }
              },
              deep: true
          }
      },
      computed:{
          ...mapGetters({
              animaux: 'identification/animaux',
              constantes: 'identification/constantes',
              selectedAnimal: 'identification/selectedAnimal'
          }),
          animalConstantes(){
              if(this.selectedAnimal !== null) return [...this.constantes].filter(item => item.animal === this.selectedAnimal)
              return []
          },
          animalIso(){
              if(this.selectedAnimal !== null){
                  let a = this.animaux.find(item => item.uid === this.selectedAnimal)
                  if(a) return a.codeIso
                  return '-'
              }
              return '-'
          },
          chartOptions() {
              return {
                  chart: {
                      id: 'frequence-cardiac-respiratoire-chart',
                      type: 'line',
                      toolbar: {
                          show: false
                      }
                  },
                  colors: ['#b7a90e', '#2375e0'],
                  stroke: {
                      curve: 'smooth'
                  },
                  title: {
                      text: 'Frequence respiratoire et cardiaque de '+ this.animalIso,
                      align: 'left'
                  },
                  legend: {
                     position: 'bottom',
                     horizontalAlign: 'right'
                  },
                  yaxis: [{
                      title: {
                          text: 'Fréquence',
                      },
                    //   max: 120
              
                      }
                  ],
                  xaxis: {
                      type: 'datetime',
                      categories: this.dates,
                  },
              }
          },
          series(){
              return [
                  {
                      name: "Frequence respiratoire",
                      data: this.frs
                  },
                  {
                      name: "Frequence cardiaque",
                      data: this.fcs
                  }
              ]   
          }
          
      }
  }
  </script>
  
  <style>
  
  </style>